








































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { Choice, VForm } from "@/types";
import AxiosMixin from "@/mixins/axiosMixin";
import EditMixin from "@/mixins/editMixin";
import RulesMixin from "#/mixins/rulesMixin";
import PatientMixin from "@/mixins/patientMixin";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import DateInput from "#/components/DateInput.vue";
import {
  MedicalInsurance,
  MedicalInsuranceOldAge,
  MedicalInsuranceLimitCert,
  DefaultMedicalInsuranceLimitCert,
  LimitCertLimitDiv,
} from "#/model/patient";
import * as appDate from "#/utility/appDate";
import { PATIENT } from "#/const";

@Component({
  components: {
    PatientHeader,
    DateInput,
  },
})
export default class InsuranceInfoLimitCert extends Mixins(
  AxiosMixin,
  EditMixin,
  RulesMixin,
  PatientMixin
) {
  @Ref("patient-header") private readonly patientHeader!: VForm;

  private insLimitCertId = 0;
  private patientId = 0;
  private mode = "";

  /** 限度額適用認定証 */
  private limitCert: MedicalInsuranceLimitCert =
    DefaultMedicalInsuranceLimitCert();

  /** 認定証の種類 */
  private certDivs: Choice[] = [];

  /** 適用区分 */
  private limitDivs: LimitCertLimitDiv[] = [];

  /** 選んでいる認定証の種類 1:限度額適用認定証 2:限度額適用・標準負担額減額認定証 */
  private certDiv = 1;

  /** 医療保険(開始日の遅い順) */
  private medicalInsurances: MedicalInsurance[] = [];

  /** 高齢受給者証(開始日の遅い順) */
  private oldAges: MedicalInsuranceOldAge[] = [];

  /** 限度額の期間に有効な医療保険(後期高齢) */
  private get MedicalInsuranceLateNow() {
    return this.medicalInsurances.find((ins) => {
      if (ins.insurance_type === PATIENT.INSURANCE_TYPE_LATE) {
        return appDate.checkInnerTime(
          this.limitCert.start_date,
          this.limitCert.end_date,
          ins.start_date,
          ins.end_date
        );
      }
    });
  }

  /** 限度額の期間に有効な医療保険(国保・社保) */
  private get MedicalInsuranceOldAgeNow() {
    return this.medicalInsurances.find((ins) => {
      const isMatchType =
        ins.insurance_type === PATIENT.INSURANCE_TYPE_NATIONAL ||
        ins.insurance_type === PATIENT.INSURANCE_TYPE_SOCIAL;
      if (isMatchType && ins.is_old_age_certificate) {
        return appDate.checkInnerTime(
          this.limitCert.start_date,
          this.limitCert.end_date,
          ins.start_date,
          ins.end_date
        );
      }
    });
  }

  /** 限度額の期間に有効な高齢受給者証 */
  private get OldAgeNow() {
    return this.oldAges.find((oldAge) => {
      return appDate.checkInnerTime(
        this.limitCert.start_date,
        this.limitCert.end_date,
        oldAge.start_date,
        oldAge.end_date
      );
    });
  }

  /** 高額療養費を作成できない内容というアラートを表示するか */
  private get IsWarnNotHighExpense() {
    const oldAgePay70 = this.OldAgeNow?.payment_rate === 70;
    const oldAgePay80 = this.OldAgeNow?.payment_rate === 80;
    const oldAgeDiv1 = this.OldAgeNow?.insured_div === 1;
    const oldAgeDiv7 = this.OldAgeNow?.insured_div === 2;

    const insOldPay70 = this.MedicalInsuranceOldAgeNow?.payment_rate === 70;
    const insOldPay80 = this.MedicalInsuranceOldAgeNow?.payment_rate === 80;
    const insOldDiv1 =
      this.MedicalInsuranceOldAgeNow?.insured_div === PATIENT.INSURED_DIV_OLD;
    const insOldDiv7 =
      this.MedicalInsuranceOldAgeNow?.insured_div ===
      PATIENT.INSURED_DIV_OLDSEVEN;

    let isShowAlert = false;
    if (this.isOverAge(this.limitCert.end_date, 75)) {
      // 75歳以上の場合、医療保険(後期高齢)の内容次第でメッセージを表示する
      switch (this.MedicalInsuranceLateNow?.payment_rate) {
        case 70:
          // 適用区分「低所得Ⅰ」または「低所得Ⅱ」を選択している
          if ([8, 9].includes(this.limitCert.limit_div_id)) {
            isShowAlert = true;
          }
          break;
        case 80:
          isShowAlert = true;
          break;
        case 90:
          // 適用区分「現役並みⅠ」または「現役並みⅡ」を選択している
          if ([6, 7].includes(this.limitCert.limit_div_id)) {
            isShowAlert = true;
          }
          break;
      }
    } else if (this.isLimitCertOverAge(this.limitCert.end_date, 70)) {
      // 70歳以上75歳未満の場合、高齢受給者証または医療保険(兼高齢)の内容次第でメッセージを表示する
      // 限度額適用認定証の登録時
      //   有効な高齢受給者証が高齢一かつ2割負担なら、エラー
      //   有効な医療保険が高齢一かつ8割給付なら、エラー
      // 限度額適用・標準負担額減額認定証の登録時...
      //   有効な高齢受給者証が高齢7かつ3割負担なら、エラー
      //   有効な医療保険が高齢7かつ7割給付なら、エラー
      if (this.certDiv === 1) {
        if ((oldAgeDiv1 && oldAgePay80) || (insOldDiv1 && insOldPay80)) {
          isShowAlert = true;
        }
      } else if (this.certDiv === 2) {
        if ((oldAgeDiv7 && oldAgePay70) || (insOldDiv7 && insOldPay70)) {
          isShowAlert = true;
        }
      }
    }

    return isShowAlert;
  }

  /** 選択可能な適用区分 */
  private get SelectableLimitDivs() {
    return this.limitDivs.filter((limitDiv) => {
      if (limitDiv.cert_div !== this.certDiv) {
        return false;
      }
      // high_expense_term_id 限度額適用認定証期間ID 3:70歳未満 4:70歳以上
      if (this.isLimitCertOverAge(this.limitCert.end_date, 70)) {
        return limitDiv.high_expense_term_id === 4;
      } else {
        return limitDiv.high_expense_term_id === 3;
      }
    });
  }

  /**
   * 限度額適用認定証の終了日が指定した年齢以上かどうかの判定
   * https://ewell-ibow.backlog.com/view/IV2-3534
   * @param targetDate 誕生日を求める基準日(yyyy-MM-dd形式)
   * @param minAge 年齢の下限
   */
  protected isLimitCertOverAge(targetDate: string, minAge: number) {
    if (targetDate === "") {
      targetDate = this.dateToStr(new Date(), "yyyy-MM-dd");
    }

    const rawBirthday = this.strToDate(this.patientInfo.bday);
    // 誕生日が1日以外の場合は翌月1日と考える
    const birthday = this.addMonthAndSetToFirst(rawBirthday);

    const bMonth = birthday.getMonth();
    const patientMinAgeDay = birthday;
    patientMinAgeDay.setFullYear(patientMinAgeDay.getFullYear() + minAge);
    // 閏年の2月29日から、minAge年後の3月1日になってしまった場合、みなし誕生日の2月28日に補正する
    if (patientMinAgeDay.getMonth() !== bMonth) {
      patientMinAgeDay.setDate(0);
    }

    return targetDate >= this.dateToStr(patientMinAgeDay, "yyyy-MM-dd");
  }

  /** 指定した日付が1日以外の場合 翌月1日に変換 */
  protected addMonthAndSetToFirst(birthday: Date): Date {
    const newDate = new Date(birthday);
    // 1日以外の場合は翌月1日に設定
    if (newDate.getDate() !== 1) {
      newDate.setMonth(newDate.getMonth() + 1);
      newDate.setDate(1);
    }
    return newDate;
  }

  created() {
    this.insLimitCertId = Number(this.$route.params.inslimitcertid);
    this.patientId = Number(this.$route.params.id);
    this.mode = this.$route.query.mode as string;
    this.fetchLimitCert();
  }

  mounted() {
    this.setBreadItems([
      {
        text: "利用者",
        disabled: false,
        to: "/patient/list",
      },
      {
        text: this.patientInfo.name,
        disabled: false,
        to: `/patient/${this.patientId}/insurance?key=LimitCert`,
      },
      {
        text: "限度額適用認定証",
        disabled: true,
        to: "",
      },
    ]);
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** 登録 */
  private async save() {
    if (!this.patientHeader.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    const highExpMsg = "高額療養費を登録できない内容です。よろしいですか？";
    if (this.IsWarnNotHighExpense && !(await this.$openConfirm(highExpMsg))) {
      return;
    }
    //高額療養費を自動作成する
    this.limitCert.is_make_high_expense = Number(!this.IsWarnNotHighExpense);

    if (this.mode) {
      this.limitCert.id = 0;
    }
    this.limitCert.patient_id = this.patientId;
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/limitcert/save",
      {
        limit_cert: this.limitCert,
      },
      async (res) => {
        if (res.data.code > 0) {
          await this.$openAlert(res.data.message);
          return;
        }

        this.setNoEditMode();
        this.cancel();
      }
    );
  }

  /** 変更 */
  private update() {
    this.limitCert.id = this.insLimitCertId;
    this.save();
  }

  /** 削除 */
  private async clickDelete() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.limitCert.id = this.insLimitCertId;
      this.postJsonCheck(
        window.base_url + "/api/patient/insurance/limitcert/delete",
        { limit_cert: this.limitCert },
        () => {
          this.setNoEditMode();
          this.cancel();
        }
      );
    }
  }

  /** 限度額適用認定証を取得 */
  private fetchLimitCert() {
    this.postJsonCheck(
      window.base_url + "/api/patient/insurance/limitcert/get",
      {
        patient_id: this.patientId,
        ins_limit_cert_id: this.insLimitCertId,
      },
      (res) => {
        this.certDivs = res.data.cert_divs;
        this.limitDivs = res.data.limit_divs;
        this.medicalInsurances = res.data.medical_insurances;
        this.oldAges = res.data.old_ages;
        /** 編集する限度額適用認定証 */
        const limitCert: MedicalInsuranceLimitCert = res.data.limit_cert;
        if (limitCert) {
          if (this.mode) {
            // コピー作成時、期間は空にする
            limitCert.start_date = "";
            limitCert.end_date = "";
          }
          this.limitCert = limitCert;
          this.certDiv = limitCert.limit_div.cert_div;
        }

        this.setLoaded();
      }
    );
  }

  /** 適用区分の選択チェック */
  private checkLimitDiv() {
    const isSelectingLimitDiv = this.SelectableLimitDivs.some((limitDiv) => {
      return limitDiv.id === this.limitCert.limit_div_id;
    });
    return isSelectingLimitDiv || "必須です";
  }

  /** 検索条件バリデーション：期間の開始日 */
  private checkStartDate(): boolean | string {
    const startDate = this.newDate(this.limitCert.start_date);
    const endDate = this.newDate(this.limitCert.end_date);
    //高額療養費を作成する場合
    if (this.limitCert.is_make_high_expense) {
      const target = this.newDate("2018-08-01");
      if (target > startDate) {
        return "高額療養費への登録は有効期限（開始日）が平成30年8月1日(2018年8月1日)以降の場合に可能です";
      }
    }
    //開始日が終了日よりも後である場合
    if (startDate > endDate) {
      return "開始日は終了日よりも前に設定してください";
    }
    return true;
  }

  /** 検索条件バリデーション：期間の終了日 */
  private checkEndDate(): boolean | string {
    const startDate = this.newDate(this.limitCert.start_date);
    const endDate = this.newDate(this.limitCert.end_date);
    //終了日が開始日よりも前である場合
    if (startDate > endDate) {
      return "終了日は開始日よりも後に設定してください";
    }
    return true;
  }

  //-----------------------------------------------------
  // Watch表示切り替え
  //-----------------------------------------------------
  //編集モード設定
  @Watch("limitCert", { deep: true })
  private watchData() {
    if (this.IsLoaded) {
      this.setEditMode();
    }
  }
}
